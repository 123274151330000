<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
<!--						<a-avatar :size="74" shape="square" src="images/face-1.jpg" />-->
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ accountDetails.first_name }} {{ accountDetails.last_name }}</h4>
							<p>Checking Account</p>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
            <div class="avatar-info" v-if="availableBalance">
              <h4 class="font-semibold m-0">${{ availableBalance }}</h4>
              <p>Available Balance</p>
            </div>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24">
      <a-col :span="24" :md="8" class="mb-24">

        <!-- Profile Information Card -->
<!--        <CardProfileInformation></CardProfileInformation>-->
        <!-- / Profile Information Card -->

      </a-col>
			<!-- Platform Settings Column -->
			<a-col :span="24" :md="8" class="mb-24">
        <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
        >
        <a-form-item class="mb-10">
  <a-select v-model="tType"
    v-decorator="[
      'select_field',
      { rules: [{ required: true, message: 'Please select an option' }] },
    ]"
    placeholder="Select a transfer type"
  >
    <a-select-option value="Local">Local</a-select-option>
    <a-select-option value="International">International</a-select-option>
  </a-select>
</a-form-item>
          <a-form-item class="mb-10">
            <a-input
                v-decorator="[
						'account_name',
						{ rules: [{ required: true, message: 'Please input account name of beneficiary!', pattern: new RegExp(/[A-Za-z\s]+/) }] },
						]"
                placeholder="Account Name"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="mb-10">
            <a-input
                v-decorator="[
						'account_number',
						{ rules: [{ required: true, message: 'Please input account number of beneficiary!', pattern: new RegExp(/[0-9]{6}/)}] },
						]"
                placeholder="Account Number"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="mb-10">
            <a-input
                v-decorator="[
						'bank',
						{ rules: [{ required: true, message: 'Please input beneficiary bank name'}] },
						]"
                placeholder="Bank Name"
            >
            </a-input>
          </a-form-item>
          <a-form-item v-if="tType == 'Local'" class="mb-10">
            <a-input
                v-decorator="[
						'route_number',
						{ rules: [{ required: true, message: 'Please input beneficiary routing number!', pattern: new RegExp(/[0-9]{4}/) }] },
						]"
                placeholder="Routing Number"
            >
            </a-input>
          </a-form-item>
          <a-form-item v-else class="mb-10">
            <a-input
                v-decorator="[
						'route_number',
						{ rules: [{ required: true, message: 'Please input beneficiary routing number!', pattern: new RegExp(/[0-9]{4}/) }] },
						]"
                placeholder="Swift Code"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="mb-10">
            <a-input
                v-decorator="[
						'address',
						{ rules: [{ required: true, message: 'Please input beneficiary address!' }] },
						]"
                placeholder="Address"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="mb-10">
            <a-input
                v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input beneficiary email!', pattern: new RegExp(/^\S+@\S+$/) }] },
						]"
                placeholder="Email"
            >
            </a-input>
          </a-form-item>

          <a-form-item class="mb-10">
            <a-input

                v-decorator="[
						'amount',
						{ rules: [{ required: true, message: 'Please input amount!', pattern: new RegExp(/0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*/)}] },
						]"
                placeholder="Amount"
            >
            </a-input
            >
          </a-form-item>
          <a-form-item class="mb-5" label="OTP" :colon="false" v-if="otpStatus">
            <a-input
                v-decorator="[
						'otp',
						{ rules: [{ required: true, message: 'Please enter otp sent to your email' }] },
						]" type="password" />
            <a-alert message="You are required to enter an OTP code sent to your email address. Enter it above to complete your transfer! " type="info" />
          </a-form-item>

          <div  v-if="otpStatus && !isLoading">
            <a-form-item>
              <a-button type="primary" block html-type="submit" class="login-form-button" @click="submitOtp()">
                SUBMIT OTP $ SEND
              </a-button>
            </a-form-item>
          </div>

          <div v-else-if="isLoading" style="text-align: center">
            <a-spin size="large" />
          </div>

          <div v-else>
            <a-form-item>
              <a-button type="primary" block html-type="submit" class="login-form-button" @click="submit()">
                SEND MONEY
              </a-button>
            </a-form-item>

          </div>
          <a-alert v-if="loginFailed" style="margin-bottom: 15px" :message="failedMessage" type="error" show-icon />
          <div style="text-align: center">
            <a-icon v-if="resendToken" type="reload" theme="outlined" @click="getOtp()"/> <i v-if="resendToken">Resend Token</i> <i v-if="timer > 0 && otpStatus">Resend Token in {{timer}}</i>
          </div>
          <a-alert v-if="successful" class="mb-10" message="Transfer initiated successfully!" type="success" show-icon />
<!--          <a-alert v-if="emailExist" class="mb-10" message="Account with this email already exists!" type="info" show-icon />-->
          <a-alert v-if="failed" class="mb-10" :message="message" type="error" show-icon />

        </a-form>

				<!-- Platform Settings Card -->
<!--				<CardPlatformSettings></CardPlatformSettings>-->
				<!-- / Platform Settings Card -->

			</a-col>
			<!-- / Platform Settings Column -->

			<!-- Profile Information Column -->
			<!-- / Profile Information Column -->
			
			<!-- Conversations Column -->
			<a-col :span="24" :md="8" class="mb-24">
			
				<!-- Conversations Card -->
<!--				<CardConversations-->
<!--					:data="conversationsData"-->
<!--				></CardConversations>-->
				<!-- / Conversations Card -->

			</a-col>
			<!-- / Conversations Column -->

		</a-row>

	</div>
</template>

<script>
  const axios = require("../axios")
	import CardPlatformSettings from "../components/Cards/CardPlatformSettings"
	import CardProfileInformation from "../components/Cards/CardProfileInformation"
	import CardConversations from "../components/Cards/CardConversations"
	import CardProject from "../components/Cards/CardProject"

	// Conversation's list data.
	// Project cards data


	export default ({
		components: {
			CardPlatformSettings,
			CardProfileInformation,
			CardConversations,
			CardProject,
		},
		data() {
			return {
				// Active button for the "User Profile" card's radio button group.
				profileHeaderBtns: 'overview',

				// Associating Conversation's list data with its corresponding property.
        accountDetails: {},
        accountNo: "",
        accountName: "",
        otp: null,
        otpStatus: false,
        email: "",
        address: "",
        bankName: "",
        rounting_no: 0,
        amount: 0,
        isLoading: false,
        loginFailed: false,
        failedMessage: "Failed",
        resendToken: false,
        timer: 60,
        runCountDown: false,
        successful: false,
        failed: false,
        message: "",
        tType: 'Local'

				// Project cards data
			}
		},
    beforeCreate() {
      // Creates the form and adds to it component's "form" property.
      this.form = this.$form.createForm(this, { name: 'normal_login' });
    },

    computed:{
		  availableBalance(){
		    if (this.accountDetails.current_balance){
		      return this.accountDetails.current_balance.toLocaleString()
        }else{
		      return null
        }
      }
    },

    mounted() {
		  this.getAccountDetails()
    },
    methods: {
      async getAccountDetails() {
        // this.isLoadingDetail = true
        this.resendToken = false
        const authe = {
          headers: {Authorization: "Bearer " + localStorage.getItem("token")},
        };
        try {
          const response = await Object.freeze(
              axios.get("/account/account_details/", authe)
          );
          console.log(response.data);
          this.accountDetails = response.data

        } catch (e) {
          console.log(e.response);
          this.isLoadingDetail = false
        }
      },
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if ( !err ) {
            console.log('Received values of form: ', values) ;
            this.accountNo = values.account_number;
            this.accountName = values.account_name;
            this.address = values.address;
            this.rounting_no = values.route_number;
            this.bankName = values.bank;
            this.email = values.email;
            this.amount = values.amount;
            if (!this.otpStatus){
              this.getOtp()
            }
            this.resendToken = false
            if (this.runCountDown){
              this.timer = 240;
            }else{
              this.timer = 240;
            }
            if(!this.runCountDown) {
              this.countDown()
            }

            if (values.otp){
              this.otp = values.otp
              this.sendMoney()
            }
          }
        });
      },
      async getOtp(){
        try{
          this.isLoading = true;
          const authe = {
            headers: {Authorization: "Bearer " + localStorage.getItem("token")},
          };
          const response = await axios.get("/account/get-otp/", authe)
          this.isLoading = false;
          console.log(response)
          if (response.data === "success"){
            this.otpStatus = true;
          }
        }catch (e) {
          console.log(e.response)
          this.isLoading = false;
        }
        this.resendToken = false
        if (this.runCountDown){
          this.timer = 240;
        }else{
          this.timer = 240;
        }
        // if(!this.runCountDown) {
        //   this.countDown()
        // }
      },
      async sendMoney(){
        this.failed = false
        this.successful = false
        this.resendToken = false
        this.otpStatus = false
        try{
          this.isLoading = true;
          const authe = {
            headers: {Authorization: "Bearer " + localStorage.getItem("token")},
          };
          let data = {
            amount: this.amount,
            route: this.rounting_no,
            account: this.accountNo,
            name: this.accountName,
            bank: this.bankName,
            address: this.address,
            email: this.email,
            otp: this.otp
          }
          const response = await axios.post("/account/transfer_successful/", data, authe)
          console.log(response)
          if(response.data === "success"){
            this.successful = true;
            this.resendToken = false
            this.otpStatus = false
            this.isLoading = false
            this.getAccountDetails()
            window.setTimeout(() => {
              this.$router.push("/overview")
            }, 30000)
          }else if(response.data === "OTP Incorrect"){
            this.message = "OTP Incorrect!"
            this.failed = true
            this.isLoading = false
            this.resendToken = true
            this.otpStatus = true
          }else if(response.data === "pending"){
            this.message = "You have a pending transaction that needs to be completed before you can make another transfer!"
            this.failed = true
            this.isLoading = false
          }else if(response.data === "not enough funds"){
            this.message = "Insufficient Funds!"
            this.failed = true
            this.isLoading = false

          }else{
            this.message = "An Error Occurred, Please try again later!"
            this.failed = true
            this.isLoading = false

          }
        }catch (e) {
          console.log(e.response)
          this.message = "An Error Occurred, Please try again later!"
          this.failed = true
          this.isLoading = false
          this.resendToken = true
        }
      },
      countDown(){
        this.runCountDown = true;
        window.setInterval(() => {
          // if (this.timer >= 1) {
          this.timer -= 1

          if(this.timer === 0){
            this.resendToken = true
          }
        }, 1000);
      },
    }
	})

</script>

<style lang="scss">
</style>